import { Component } from '@plone/volto/components';
import { getResourceVocabularyItemTitle } from '../../../helpers/getResourceVocabulary';

export const ResourceGridListingItemTemplate = (props) => {
  const { item, featured } = props;
  const voc_element = getResourceVocabularyItemTitle(item.resource_type_index);
  return (
    <div className="card-container item-resource">
      {item.image_field !== '' && (
        <Component
          componentName="PreviewImage"
          item={item}
          alt=""
          className="item-image"
        />
      )}
      <div className="item">
        <div className="content">
          {featured ? (
            <h3>{item.title || item.id}</h3>
          ) : (
            <h4>{item.title || item.id}</h4>
          )}
          <div className="resource-type">
            {voc_element ? voc_element.label : item.resource_type_index}
          </div>
        </div>
      </div>
    </div>
  );
};
