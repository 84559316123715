import { defineMessages } from 'react-intl';
import { makeInlineElementPlugin } from '@plone/volto-slate/elementEditor';
import PdfLinkElement from './SlatePdfListing';
import { PdfLinkEditorSchema } from './schema';
import { PDFLink } from './constants';
import { withPDFLink } from './extensions';
// import fileSVG from '@plone/volto/icons/file.svg';
import pdfSVG from 'volto-itlent/theme/icons/i_pdf.svg';

const messages = defineMessages({
  edit: {
    id: 'Edit PDF link',
    defaultMessage: 'Edit PDF link',
  },
  delete: {
    id: 'Remove PDF link',
    defaultMessage: 'Remove PDF link',
  },
});

export default function installPDFLinkPlugin(config) {
  const opts = {
    title: 'PdfLink',
    pluginId: PDFLink,
    elementType: PDFLink,
    element: PdfLinkElement,
    isInlineElement: true,
    editSchema: PdfLinkEditorSchema,
    extensions: [withPDFLink],
    hasValue: (formData) => !!formData,
    toolbarButtonIcon: pdfSVG,
    messages,
  };
  const [installEditor] = makeInlineElementPlugin(opts);
  config = installEditor(config);
  return config;
}
