import { getVocabulary } from '@plone/volto/actions';
import { GET_VOCABULARY } from '@plone/volto/constants/ActionTypes';
import { useSelector } from 'react-redux';

// import { getSite } from '@plone/volto/actions';

const vocabNameOrURL = 'itlent.addon.ResourceType';
const getResourceVocabulary = {
  path: '/',
  extend: (dispatchActions) => {
    if (
      dispatchActions.filter(
        (asyncAction) => asyncAction.key === GET_VOCABULARY,
      ).length === 0
    ) {
      dispatchActions.push({
        key: GET_VOCABULARY,
        promise: ({ location, store: { dispatch } }) =>
          __SERVER__ && dispatch(getVocabulary({ vocabNameOrURL })),
      });
    }
    return dispatchActions;
  },
};

export { getResourceVocabulary };

export function getResourceVocabularyItemTitle(voc_token) {
  const resource_vocabulary = useSelector(
    (state) => state?.vocabularies['itlent.addon.ResourceType'].items,
  );

  return resource_vocabulary.find((element) => {
    return element.value === voc_token;
  });
}
