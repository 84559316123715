export const PdfLinkEditorSchema = {
  title: 'Pdf Link',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['link'],
    },
  ],
  properties: {
    link: {
      title: 'Link',
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
      allowExternals: true,
    },
  },
  required: [],
};
