import { PDFLink } from './constants';

export const withPDFLink = (editor) => {
  const { normalizeNode, isInline } = editor; // we can also normalize plugin data here

  editor.isInline = (element) => {
    return element?.type === PDFLink ? true : isInline(element);
  };

  return editor;
};
