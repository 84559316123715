// SemanticUI-free pre-@plone/components
import React from 'react';

import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  useIntl,
} from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink, Logo } from '@plone/volto/components';
import { Container } from '@plone/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import FPImage from 'volto-itlent/theme/images/fp.png';
import EuskoJaurlaritzaImage from 'volto-itlent/theme/images/eusko_jaurlaritza.png';
import LogoImage from '@plone/volto/components/theme/Logo/Logo.svg';

const messages = defineMessages({
  site: {
    id: 'Site',
    defaultMessage: 'Site',
  },
  plonesite: {
    id: 'Plone Site',
    defaultMessage: 'Plone Site',
  },
  contactlink: {
    id: 'Contact link',
    defaultMessage: 'Jarri harremanetan',
  },
  fp: {
    id: 'fp',
    defaultMessage: 'FP',
  },
  euskojaurlaritza: {
    id: 'euskojaurlaritza',
    defaultMessage: 'Eusko Jaurlaritza',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );
  const site = useSelector((state) => state.site.data);
  return (
    <footer id="footer">
      <Container layout className="footer">
        <div className="footer-flex-element logo">
          <img
            src={
              site['plone.site_logo']
                ? flattenToAppURL(site['plone.site_logo'])
                : LogoImage
            }
            className="logo_xl"
            alt={intl.formatMessage(messages.plonesite)}
            title={intl.formatMessage(messages.plonesite)}
          />
          <ul>
            {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
            {siteActions?.length
              ? siteActions.map((item) => (
                  <li className="item" key={item.id}>
                    <UniversalLink
                      className="item"
                      href={
                        settings.isMultilingual
                          ? `/${lang}/${
                              item.url
                                ? flattenToAppURL(item.url)
                                : addAppURL(item.id)
                            }`
                          : item.url
                          ? flattenToAppURL(item.url)
                          : addAppURL(item.id)
                      }
                    >
                      {item?.title}
                    </UniversalLink>
                  </li>
                ))
              : null}
          </ul>
        </div>
        <div className="footer-flex-element contact-info">
          <ul>
            <li class="address">
              Zamalbide Auzoa z/g 20100 Errenteria (Gipuzkoa)
            </li>
            <li class="telephone">
              <a href="tel:943082900">943 08 29 00</a>
            </li>
            <li class="mail-contact">
              <a href={`/${lang}/itlent/harremanetarako`}>
                {intl.formatMessage(messages.contactlink)}
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-flex-element partners">
          <img
            src={FPImage}
            className="partners-fp"
            alt={intl.formatMessage(messages.fp)}
            title={intl.formatMessage(messages.fp)}
          />
          <img
            src={EuskoJaurlaritzaImage}
            className="partners-euskojaurlaritza"
            alt={intl.formatMessage(messages.euskojaurlaritza)}
            title={intl.formatMessage(messages.euskojaurlaritza)}
          />
        </div>
      </Container>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
