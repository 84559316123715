import config from '@plone/volto/registry';
import messages from './messages.js';

export const FeaturedResourcesSchema = (intl, data) => {
  return {
    title: intl.formatMessage(messages.featuredresourcesconfig),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title'],
      },
      {
        id: 'buttons',
        title: 'Buttons config',
        fields: [
          'button1_text',
          'button1_link',
          'button2_text',
          'button2_link',
        ],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.title),
        type: 'string',
      },
      button1_text: {
        title: intl.formatMessage(messages.button1_text),
        type: 'string',
      },
      button1_link: {
        title: intl.formatMessage(messages.button1_link),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
      button2_text: {
        title: intl.formatMessage(messages.button2_text),
        type: 'string',
      },
      button2_link: {
        title: intl.formatMessage(messages.button2_link),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
    },
    required: ['title'],
  };
};
