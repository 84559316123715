import { GET_FEATURED_RESOURCES } from '../../actions';

const getInitialState = {
  error: null,
  loaded: false,
  loading: false,
  featured_resources: {},
};

export const featuredResourcesReducer = (
  state = getInitialState,
  action = {},
) => {
  switch (action?.type) {
    case `${GET_FEATURED_RESOURCES}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };

    case `${GET_FEATURED_RESOURCES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };

    case `${GET_FEATURED_RESOURCES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        featured_resources: action.result,
      };

    default:
      return state;
  }
};
