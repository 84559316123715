import { withBlockExtensions } from '@plone/volto/helpers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeaturedResources } from '../../../actions/featured_resources/get_featured_resources';
import Resource from '../../Resource/Resource';
import { Button } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import cx from 'classnames';
const FeaturedResourcesView = ({ data, className, isEditMode = false }) => {
  let dispatch = useDispatch();
  let lang = useSelector((state) => state.intl.locale);
  const featured_resources = useSelector(
    (state) => state?.featured_resources?.featured_resources,
  );
  const featured_element = featured_resources?.featured_item;
  const items = featured_resources?.items;
  useEffect(() => {
    dispatch(getFeaturedResources(lang));
  }, [dispatch, lang]);

  const { title, button1_text, button2_text, button1_link, button2_link } =
    data;

  return (
    <div className="featured-resources-wrapper">
      {title && <h2>{title}</h2>}
      <div
        className={cx('featured-resources', {
          'with-featured': featured_element,
          'without-featured': !featured_element,
        })}
      >
        {featured_element ? (
          <>
            <div className="featured-element">
              <Resource
                item={featured_element}
                featured={true}
                isEditMode={isEditMode}
              />
            </div>
            {items?.length > 0 && (
              <>
                {items.map((item, index) => (
                  <>
                    <Resource
                      item={item}
                      index={index}
                      isEditMode={isEditMode}
                    />
                  </>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {items?.length > 0 && (
              <>
                {items.map((item, index) => (
                  <>
                    <Resource
                      item={item}
                      index={index}
                      isEditMode={isEditMode}
                    />
                  </>
                ))}
              </>
            )}
          </>
        )}
      </div>

      {(button1_text || button2_text) && (
        <div class="more-links-buttons">
          {button1_text && (
            <ConditionalLink
              to={button1_link[0]['@id']}
              condition={!isEditMode}
            >
              <Button className="more-button">{button1_text}</Button>
            </ConditionalLink>
          )}
          {button2_text && (
            <ConditionalLink
              to={button2_link[0]['@id']}
              condition={!isEditMode}
            >
              <Button className="more-button">{button2_text}</Button>
            </ConditionalLink>
          )}
        </div>
      )}
    </div>
  );
};

export default withBlockExtensions(FeaturedResourcesView);
