import LeftColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/LeftColumnFacets';
import RightColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/RightColumnFacets';
import iconSVG from '@plone/volto/icons/circle-top.svg';
import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema.js';

import { getResourceVocabulary } from '../../helpers/getResourceVocabulary.js';
import { FeaturedResourcesEdit } from './FeaturedResources/Edit.jsx';
import FeaturedResourcesView from './FeaturedResources/View.jsx';
import {
  ResourceGridListingItemTemplate,
  NewsItemGridListingItemTemplate,
} from './GridListingItemsTemplates';
import GridListingFourColumns from './Listing/GridListingFourColumns.jsx';
import GridListingThreeColumns from './Listing/GridListingThreeColumns.jsx';

import installPDFLinkPlugin from '../../editor/plugins/SlatePdfPlugin';
import { PDFLink } from '../../editor/plugins/SlatePdfPlugin/constants';

const customBlocks = (config /* , restrictedBlocks */) => {
  const BG_COLORS = [
    { name: 'transparent', label: 'Transparent' },
    { name: 'grey', label: 'Grey' },
    { name: 'primary', label: 'Blue' },
    { name: 'light-blue', label: 'Light Blue' },
  ];

  config.blocks.blocksConfig.listing.colors = BG_COLORS;

  // restrictedBlocks.forEach(
  //   (rBlock) => (config.blocks.blocksConfig[rBlock].restricted = true),
  // );
  const allowedGridBlocks = [
    '__button',
    'countUpBlock',
    'teaser',
    'image',
    'listing',
    'slate',
    'text',
    'homeFeatured',
    'video',
  ];
  config.blocks.blocksConfig.gridBlock = {
    ...config.blocks.blocksConfig.gridBlock,
    colors: BG_COLORS,
    allowedBlocks: [
      ...config.blocks.blocksConfig.gridBlock.allowedBlocks,
      ...allowedGridBlocks,
    ],
  };
  allowedGridBlocks.forEach(
    (aBlock) =>
      (config.blocks.blocksConfig.gridBlock.blocksConfig[aBlock] =
        config.blocks.blocksConfig[aBlock]),
  );

  config.blocks.blocksConfig.search = {
    ...config.blocks.blocksConfig.search,
    variations: [
      ...config.blocks.blocksConfig.search.variations,
      {
        id: 'facetsRightSide',
        title: 'Facets on right side',
        view: RightColumnFacets,
        isDefault: true,
      },
      {
        id: 'facetsLeftSide',
        title: 'Facets on left side',
        view: LeftColumnFacets,
        isDefault: false,
      },
    ],
  };
  config.blocks.blocksConfig.featuredResources = {
    id: 'featuredResources',
    title: 'Featured Resources',
    icon: iconSVG,
    edit: FeaturedResourcesEdit,
    view: FeaturedResourcesView,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
    group: 'common',
  };

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'grid_three_columns',
      title: 'Grid 3 Columns',
      template: GridListingThreeColumns,
    },
    {
      id: 'grid_four_columns',
      title: 'Grid 4 Columns',
      template: GridListingFourColumns,
    },
  ];
  // Custom template for Grid Variation
  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: ResourceGridListingItemTemplate,
    dependencies: 'Resource',
  });
  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: NewsItemGridListingItemTemplate,
    dependencies: 'News Item',
  });
  config.settings.asyncPropsExtenders = [
    ...config.settings.asyncPropsExtenders,
    getResourceVocabulary,
  ];

  config.blocks.blocksConfig.group = {
    ...config.blocks.blocksConfig.group,
    schemaEnhancer: defaultStylingSchema,
    colors: BG_COLORS,
  };

  config.blocks.blocksConfig.slate = {
    ...config.blocks.blocksConfig.slate,
    colors: BG_COLORS,
  };

  // TEST 1
  config.settings.slate.toolbarButtons = [
    ...(config.settings.slate.toolbarButtons || []),
    PDFLink,
  ];
  config.settings.slate.expandedToolbarButtons = [
    ...(config.settings.slate.expandedToolbarButtons || []),
    PDFLink,
  ];
  config = installPDFLinkPlugin(config);
};
export default customBlocks;
