import React from 'react';
import { Component } from '@plone/volto/components';
import { ConditionalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import cx from 'classnames';
const ItemBodyTemplate = ({ item, featured }) => {
  const hasType = item['@type'];
  const CustomItemBodyTemplate = config.getComponent({
    name: 'GridListingItemTemplate',
    dependencies: [hasType],
  }).component;

  return CustomItemBodyTemplate ? (
    <CustomItemBodyTemplate item={item} featured={featured} />
  ) : (
    <div className="card-container">
      {item.image_field !== '' && (
        <Component
          componentName="PreviewImage"
          item={item}
          alt=""
          className="item-image"
        />
      )}
      <div className="item">
        <div className="content">
          {item?.head_title && (
            <div className="headline">{item.head_title}</div>
          )}

          <h2>{item?.title}</h2>
          {!item.hide_description && <p>{item?.description}</p>}
        </div>
      </div>
    </div>
  );
};
const Resource = ({ item, featured = false, index = 0, isEditMode }) => {
  return (
    <div className={cx('resource', `resource${index + 1}`)}>
      <ConditionalLink to={item['@id']} condition={!isEditMode}>
        <ItemBodyTemplate item={item} featured={featured} />
      </ConditionalLink>
    </div>
  );
};

export default Resource;
