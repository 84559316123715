import customBlocks from './components/Blocks/customBlocks';
import ResourceView from './components/Views/ResourceView';
import reducers from './reducers';
import installTooltipPlugin from './editor/plugins/SlatePdfPlugin';
import { TOOLTIP } from './editor/plugins/SlatePdfPlugin/constants';
// const restrictedBlocks = ['html'];

const applyConfig = (config) => {
  config.settings.supportedLanguages = ['eu', 'es', 'en'];
  config.settings.defaultLanguage = 'eu';
  config.settings.navDepth = 2;
  config.settings.isMultilingual = true;
  // // TEST 1
  // config.settings.slate.toolbarButtons = [
  //   ...(config.settings.slate.toolbarButtons || []),
  //   TOOLTIP,
  // ];
  // config.settings.slate.expandedToolbarButtons = [
  //   ...(config.settings.slate.expandedToolbarButtons || []),
  //   TOOLTIP,
  // ];
  // config = installTooltipPlugin(config);
  config.views.contentTypesViews['Resource'] = ResourceView;
  customBlocks(config /* , restrictedBlocks */);
  config.addonReducers = {
    ...config.addonReducers,
    ...reducers,
  };
  config.settings.siteTitleFormat = {
    includeSiteTitle: true,
    titleAndSiteTitleSeparator: '-',
  };

  return config;
};

export default applyConfig;
