/**
 * Get featured_resources
 * @module actions/featured_resources
 */
export const GET_FEATURED_RESOURCES = 'GET_FEATURED_RESOURCES';

/**
 * Get last video.
 * @function getFeaturedResources
 * @returns {Object} The last published video.
 */
export function getFeaturedResources(lang) {
  return {
    type: GET_FEATURED_RESOURCES,
    request: {
      op: 'get',
      path: `/${lang}/@featured_resources?metadata_fields=_all`,
    },
  };
}
