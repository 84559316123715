import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { useIntl } from 'react-intl';
import FeaturedResourcesView from './View';
import { FeaturedResourcesSchema } from './schema';
export const FeaturedResourcesEdit = (props) => {
  const { block, data, selected } = props;
  const handleChange = (id, value) => {
    props.onChangeBlock(block, {
      ...data,
      [id]: value,
    });
  };
  const intl = useIntl();
  const schema = FeaturedResourcesSchema(intl, data);
  return (
    <>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          formData={data}
          onChangeField={handleChange}
        />
      </SidebarPortal>
      <FeaturedResourcesView data={data} isEditMode={true} />
    </>
  );
};
