import { defineMessages } from 'react-intl';

const messages = defineMessages({
  featuredresourcesconfig: {
    id: 'FeaturedResources block configuration',
    defaultMessage: 'FeaturedResources configuration',
  },
  title: {
    id: 'Block header title',
    defaultMessage: 'Resources',
  },
  button1_text: {
    id: 'button1_text',
    defaultMessage: 'Button1 text',
  },
  button1_link: {
    id: 'button1_link',
    defaultMessage: 'Button1 link',
  },
  button2_text: {
    id: 'button2_text',
    defaultMessage: 'Button2 text',
  },
  button2_link: {
    id: 'button2_link',
    defaultMessage: 'Button2 link',
  },
});
export default messages;
