import { Icon } from '@plone/volto/components';
import pdfSVG from 'volto-itlent/theme/icons/i_pdf.svg';

const PdfLinkElement = (props) => {
  const { attributes, children, element } = props;
  const { data = {} } = element;
  return (
    <a className={'pdf-link'} href={data?.link?.[0]?.['@id']}>
      <Icon name={pdfSVG} />
      {children}
    </a>
  );
};

export default PdfLinkElement;
